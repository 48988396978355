
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function categoryPageTilesRT () {
    function repeatHierarchicalGroups1(hierarchicalGroups, hierarchicalGroupsIndex) {
        return [hierarchicalGroups(function () {
                function repeatSubvalues1(subvalues, subvaluesIndex) {
                    return [subvalues(function () {
                            return _createElement('a', {
                                'className': 'cm_vehicle-categories_category-container cm_vehicle-categories_link',
                                'href': this.href
                            }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container cm_vehicle-categories_link' }, _createElement('img', {
                                'className': 'cm_vehicle-categories_category-image',
                                'src': this.getImageOrDefault(this.imageUrl),
                                'alt': this.value
                            })), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container cm_vehicle-categories_link' }, _createElement('h5', { 'className': 'cm_vehicle-categories_category-title' }, this.value)));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_vehicle-categories_category-wrapper' }, !this.subvalues.length && !this.isMultiLevel ? [_createElement('a', {
                        'className': 'cm_vehicle-categories_category-container cm_vehicle-categories_link',
                        'href': this.href,
                        'key': '560'
                    }, _createElement('div', { 'className': 'cm_vehicle-categories_category-image-container cm_vehicle-categories_link' }, _createElement('img', {
                        'className': 'cm_vehicle-categories_category-image',
                        'src': this.getImageOrDefault(this.imageUrl),
                        'alt': this.value
                    })), _createElement('div', { 'className': 'cm_vehicle-categories_category-title-container cm_vehicle-categories_link' }, _createElement('h5', { 'className': 'cm_vehicle-categories_category-title' }, this.value)))] : null, this.subvalues.length ? [
                    _createElement('h1', {
                        'className': 'cm_vehicle-categories_category-wrapper-title',
                        'id': 'nav_' + this.term,
                        'key': '6441'
                    }, _createElement('a', {
                        'className': 'cm_vehicle-categories_category-wrapper-title-link cm_vehicle-categories_link',
                        'href': this.href
                    }, '\n        ', this.value, '\n      ')),
                    [this.vehicleWidget(function () {
                            return _createElement('div', { 'className': 'cm_vehicle-widget__vehicle-category-wrapper cm_vehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                                'className': 'cmTemplate_locked',
                                'key': '81'
                            }, this.vehicleString) : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })],
                    _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_vehicle-categories_category-repeater  cmRepeater_subvalues',
                            'key': '6445'
                        },
                        _map(this.subvalues, repeatSubvalues1.bind(this))
                    ])
                ] : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-categories cm_vehicle-categories_category-page cm_vehicle-categories__flat' }, [this.vehicleWidget(function () {
            return _createElement('div', { 'className': 'cm_vehicle-categories_title cm_vehicleWidget' }, '\n    Browse other categories', this.isVehicleSelected ? ` for your ${ this.vehicleString }` : '', '\n  ');
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-categories_category-wrapper-repeater  cmRepeater_hierarchicalGroups' },
        _map(this.hierarchicalGroups, repeatHierarchicalGroups1.bind(this))
    ]));
}
        export const componentNames = ["cm:vehicleWidget","cm:vehicleWidget"]